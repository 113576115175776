<template>
<div>
  <div class="container-fluid">
    <PageTitle />
  
    <div 
      v-if="isList" 
      class="card"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <h5 class="card-title">{{pageTitle}} List</h5>
          </div>
          <div class="col-md-9">
            <div class="row justify-content-end">
              <div class="col-md-5 ml-auto mb-2">
                <form @submit.prevent="doFilter()">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input 
                        v-model="filter.search"
                        type="text" 
                        class="form-control" 
                        placeholder="Search"
                      />
                      <div class="input-group-append">
                        <button class="btn btn-info" type="submit"><i class="fas fa-search"></i></button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-12 col-sm-auto col-md-auto">
                <button class="btn btn-info btn-block" type="button" @click="doReset">Reset</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
          <table class="table product-overview table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="data.data===false"><td colspan="99" class="text-center"><Loader class="mt10 mb10" /></td></tr>
              <tr v-for="(v,k) in data.data" :key="k">
                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                <td>{{ v.bul_name }}</td>
                <td><LabelStatus :status="v[statusKey]" /></td>
                <td>
                  <router-link 
                    v-if="moduleRole('Edit')"
                    :to="{name:$route.name, params:{pageSlug:v[idKey]}}"  
                    class="text-inverse icon_action act_icon" v-tooltip="'Edit'">
                    <i class="ti-marker-alt"></i>
                  </router-link> 
                  <a 
                    v-if="v.bul_id !== user.levelId && moduleRole('Edit')"
                    href="javascript:;" 
                    class="text-inverse icon_action act_icon mx-1" v-tooltip="'Change Status'"
                    @click="doChangeStatus(k,v)">
                    <i class="ti-settings"></i>
                  </a> 
                  <a 
                    v-if="v.bul_id !== user.levelId && moduleRole('Edit')"
                    href="javascript:;" 
                    class="text-inverse icon_action act_icon" 
                    v-tooltip="'Delete'"
                    @click="doDelete(k,v)">
                    <i class="ti-trash"></i>
                  </a>
                </td>
              </tr>
              <tr v-if="notFound"><td colspan="99" class="text-center"><h4>{{notFound}}</h4></td></tr>
            </tbody>
          </table>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <div class="pagination-flat float-right" v-if="data.data!==false&&(data.data||[]).length">
              <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                <span slot="prev-nav">Previous</span>
                <span slot="next-nav">Next</span>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Add':'Edit'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(submitUserLevelForm)">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    Level Access Name 
                    <span class="text-danger mr5">*</span>
                  </label>
                  <input 
                    v-model="row.bul_name"
                    type="text" 
                    class="form-control" 
                    placeholder="e.g. Administrator" 
                  /> 
                  <VValidate name="Level Access Name" v-model="row.bul_name" :rules="mrValidation.bul_name" />
                </div>
                <div class="form-group">
                  <label class="control-label">
                    Menu Role 
                    <span class="text-danger mr5">*</span>
                  </label>
                  <VSelect multiple 
                    class="vs__multiple" 
                    v-model="row.bul_menu_role" 
                    placeholder="Select Menu Access"
                    :options="mrMenuRel" 
                    :reduce="v=>v.value" 
                  />
                  <VValidate name="Menu Role" :value="((row.bul_menu_role||[]).length||null)" :rules="mrValidation.bul_menu_role" />
                </div>
                <div class="form-group">
                  <label class="control-label">
                    Module Role 
                  </label>
                  <VSelect multiple 
                    class="vs__multiple" 
                    v-model="row.bul_module_role" 
                    placeholder="Select Module Role"
                    :options="mrModuleRel" 
                    :reduce="v=>v.value" 
                  />
                </div>
                <hr/>
                <div v-if="row.bul_id !== user.levelId" class="form-group">
                  <label class="control-label">
                    Status 
                    <span class="text-danger mr5">*</span> 
                  </label>
                  <div>
                    <InputRadio v-model="row[statusKey]" name="status" option="Y" />
                    <InputRadio v-model="row[statusKey]" name="status" option="N" />
                  </div>
                  <VValidate name="Status" v-model="row[statusKey]" :rules="mrValidation[statusKey]" />
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12 text-right">
                <router-link :to="{ name: $route.name }" class="btn btn-rounded btn-light mr-2">Cancel</router-link>
                <button type="submit" class="btn btn-rounded btn-info">{{isAdd ? "Submit" : "Update"}}</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import { mapActions } from 'vuex'
export default{
  extends:GlobalVue,
  components:{PageTitle},
  data(){
    return {
      idKey:'bul_id',
      statusKey:'bul_is_active',
      mrModuleRel:[],
      mrMenuRel:[]
    }
  },
  methods: {
    ...mapActions(['watchAuth']),
    submitUserLevelForm() {
      this.doSubmitCRUD()
      if (!this.isAdd) {
        setTimeout(() => {
          this.watchAuth()
        }, 1000)
      }
    }
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>