<template>
<div class="row page-titles">
  <div class="col-md-5 align-self-center">
    <h4 class="text-themecolor">{{$parent.pageTitle}}</h4>
  </div>
  <div class="col-md-7 align-self-center text-right">
    <div class="row justify-content-end align-items-center">
      <div class="col-6 col-sm-6 d-flex justify-content-end">
        <ol class="breadcrumb">
          <li 
            class="breadcrumb-item">
            <router-link 
              :to="{ name: 'Dashboard' }"
            >
              Dashboard
            </router-link>
          </li>
          <li class="breadcrumb-item active">{{$parent.pageTitle}}</li>
        </ol>
      </div>
      <template v-if="!noAdd && $parent.moduleRole('Add')">
        <div class="col-6 col-sm-6 col-lg-3">
          <router-link v-if="$parent.isList"
            :to="{name:$route.name, params:{pageSlug:'add'}}" 
            class="btn btn-rounded btn-info d-inline d-lg-block m-l-15">
            <i class="fa fa-plus-circle"></i> Add New
          </router-link>
          <router-link v-else
            :to="{name:$route.name}" 
            class="btn btn-rounded btn-info d-inline d-lg-block m-l-15">
            <i class="fas fa-list-ul m-r-15"></i> {{$parent.objectName}} List
          </router-link>
        </div>
      </template>
      <template v-if="noAdd && !$parent.isList && $parent.moduleRole('Add')">
        <div class="col-6 col-sm-6 col-lg-3">
          <router-link
            :to="{name:redirect ? redirect : $route.name}" 
            class="btn btn-rounded btn-info d-none d-lg-block m-l-15">
            <i class="fas fa-list-ul m-r-15"></i> List {{objectName ? objectName : $parent.objectName}}
          </router-link>
        </div>
      </template>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props:{
    sortingBtn:Boolean,
    deleteBtn:Boolean,
    singlePage:Boolean,
    noAdd:Boolean,
    noHeaderEl:Boolean,
    redirect: String,
    objectName: String
  }
}
</script>